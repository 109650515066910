.highlight {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.4;
    background-color: @arg-color-functions-caution;
  }

  &-pop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 0.1;
      background-color: @arg-color-functions-caution;

      border-radius: 8px;
      animation: 500ms ease-out highlightPop;
    }
  }

  @keyframes highlightPop {
    0% {
      transform: scale(1);
      opacity: 0.4;
    }
    100% {
      transform: scale(3);
      opacity: 0;
    }
  }
}


.search-highlight {
  &-other-result {
    background-color: fade(@arg-color-search-highlight-other-result, 50%);
  }

  &-current-result {
    background-color: @arg-color-search-highlight-current-result;
    color: @arg-color-primary-dark-blue;
    font-weight: 700;
  }
}

.arg-mandatory-asterix {
  color: @arg-color-functions-invalid;
}

.clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  text-align: left;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arg-popup-area {
  position: relative;
  top: 0;
  left: 0;
}
