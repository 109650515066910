.ant-modal-footer .ant-btn {
  box-shadow: none;
  padding-left: 15px;
  padding-right: 15px;
  height: 36px;
  margin-left: 16px;

  .arg-threeDotsLoading {
    padding-left: 8px;
  }
}

.ant-form-item {
  margin-bottom: 20px;
}

/* Replace the round close to a cross */
.anticon-close-circle {
  visibility: hidden;
  color: @arg-color-primary-grey-blue;
}

.ant-alert,
.ant-notification,
.ant-notification-notice {
  font-size: var(--arg-font-size) !important;
  font-family: var(--arg-font-family) !important;
  border-radius: 4px;

  .anticon-close-circle {
    visibility: visible;
  }
}

.ant-notification-notice {
  &-close {
    background-color: transparent;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.ant-menu {

  &-item,
  &-submenu-title {
    display: flex;
    align-items: center;
  }

  &-vertical {
    max-height: 90vh;
    overflow: auto;
  }
}

.ant-modal {
  &-title {
    color: @arg-color-primary-dark-blue;
    font-size: 18px;
    font-weight: 500;
  }

  &-close-icon {
    color: @arg-color-primary-grey-blue;
  }

  &-footer,
  &-header {
    border: 0;
  }

  &-close {
    padding-right: 10px;
    padding-top: 4px;
  }

  & &-header {
    padding-left: 30px;
    //padding-right: 30px;
    padding-top: 24px;
    padding-bottom: 16px;

    margin-bottom: 0 !important;
  }

  &-footer {
    padding: 20px 0 0 0;
  }

  &-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  & &-content {
    padding: 0;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-steps {
  font-size: var(--arg-font-size) !important;
  font-family: var(--arg-font-family) !important;
}

.ant-modal,
.ant-tabs,
.ant-row {
  font-family: var(--arg-font-family);
}

.ant-slider {
  &-handle {
    &::after {
      top: -3px;
      left: -3px;
    }
  }
}
