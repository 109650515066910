.user-color {
  color: transparent;

  // Create the range colors for each vars
  each(range(10), {
      @color: "arg-color-users-@{index}";

      &-@{index} {
          color: @@color;
      }
  });

  each(range(10), {
    @color: "arg-color-users-@{index}";
    @index10: @{index} + 10;

    &-@{index10} {
      color: darken(@@color, 25%);
    }
  });
}

