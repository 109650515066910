@padding-top: 32px;

@title-font-size: 24px;
@title-font-weight: 700;

@title-margin-bottom: 28px;

@main-padding-top: @padding-top;
@main-padding-left: 130px;
@main-padding-right: @main-padding-left;
@main-padding-bottom: @main-padding-top;

.settings-home-page {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  background-color: @arg-colors-background-white;

  &.loading,
  &.error {
    align-items: center;
    justify-content: center;
  }

  &-measures {
    opacity: 0;
    width: 100%;
    height: 1px;
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;

    &-nav {
      flex: 1;
    }

    &-aside {
      display: flex;
      overflow: hidden;
      background-color: #f2f5fa;
      padding: 32px 5px 32px 32px;
      box-shadow: -6px 0 12px var(--arg-colors-shadow-grey-light);
    }

    &-main {
      flex: 1;
      display: flex;
      overflow: auto;
    }

    &-left-panel {
      display: flex;

      border-right: 1px solid var(--arg-background-color-secondary);
    }
  }

  &-body-left-panel {
      display: flex;
  }


  h1 {
    font-size: @title-font-size;
    font-weight: @title-font-weight;
  }

  .grey {
    color: @arg-color-primary-grey-blue;
  }

  .admin-tag {
    background-color: var(--arg-colors-shadow-grey-light);
    margin-right: 10px;
    padding: 0 10px;
  }

  &-button {
    &-export,
    &-import {
      --arg-text-color: var(--arg-color-primary);
      --arg-button-font-size: 14px !important;
      --arg-button-icon-font-size: 18px !important;

      &:hover {
        --arg-button-hover: var(--arg-color-primary-hover);
      }
    }
  }
}

.top-bar-right {
  --arg-button-icon-color: var(--arg-color-primary);
  --arg-button-label-color: var(--arg-color-primary);
  --arg-button-icon-font-size: var(--arg-font-size);
}

// common with the other pages (e.g. group-details-view, users-view, etc...)
.settings-layout-page {
  padding: 25px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-aside {
      display: flex;
      overflow: hidden;
      background-color: @arg-colors-light-grey;
      padding: 32px;
      box-shadow: -6px 0 12px var(--arg-colors-shadow-grey-light);
    }
  }
}
