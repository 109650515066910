:root {

    // DEPRECATED TO BE REMOVED
    --arg-colors-background-stroke: @arg-colors-background-stroke-fields;

    // ONLY NAMED COLORS CAN BE THERE
    --arg-colors-grey: #8D9DB1; //@arg-color-primary-grey-blue
    --arg-colors-grey-rgb: 141, 157, 177;
    --arg-colors-light-grey: #F2F5FA;//@arg-colors-light-grey;
    --arg-colors-white: #FFFFFF; //@arg-colors-white
    --arg-colors-white-rbg: 255, 255, 255;
    --arg-colors-black: #000000; //@arg-colors-black
    --arg-colors-black-rbg: 0, 0, 0;
    --arg-colors-red: #FF4D4F; //@arg-color-primary-red
    --arg-colors-green:#138721; //@arg-color-primary-green;
    --arg-colors-blue: #39939E; //@arg-color-primary-blue
    --arg-colors-blue-rgb: 57, 147, 158;
    --arg-colors-cat-skill-white: #f2f5fa;

    //Elevations (Shadows)
    --arg-colors-shadow: rgba(73, 100, 137, 0.33);
    --arg-popover-shadow: 0 4px 8px var(--arg-colors-shadow);
    --arg-colors-shadow-grey-light: rgba(var(--arg-colors-grey-rgb), 0.2);
    --arg-colors-shadow-grey-xtra-light: rgba(var(--arg-colors-grey-rgb), 0.2);
    --arg-colors-shadow-light: #A6B6D0;
    --arg-colors-shadow-xtra-light: #A6B6D061;
    --arg-colors-shadow-medium: #00000029;

    --shadow-transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);

    --elevation-umbra: rgba(var(--arg-colors-black-rbg), .2);
    --elevation-penumbra: rgba(var(--arg-colors-black-rbg), .14);
    --elevation-ambient: rgba(var(--arg-colors-black-rbg), .12);

    --arg-box-shadow-0: 0 0 0 var(--elevation-umbra), 0 0 0 var(--elevation-penumbra), 0 0 0 var(--elevation-ambient);
    --arg-box-shadow-1: 0 1px 3px var(--elevation-umbra), 0 1px 1px var(--elevation-penumbra), 0 2px 1px -1px var(--elevation-ambient);
    --arg-box-shadow-2: 0 1px 5px var(--elevation-umbra), 0 2px 2px var(--elevation-penumbra), 0 3px 1px -2px var(--elevation-ambient);

    --arg-box-shadow-default: 0px 3px 6px var(--arg-colors-shadow-medium);

    // border-radius
    --arg-metrics-border-radius-medium: 6px;
}
